




















































import Vue from "vue";
import firebase from "@/plugins/firebase";
import { RawLocation } from "vue-router";

export default Vue.extend({
  metaInfo: {
    title: "Kullanıcı Girişi"
  },
  data: () => ({
    form: {
      email: "",
      password: ""
    },
    alert: false,
    message: "",
    error: false
  }),
  computed: {
    emailRules() {
      return [
        (v: any) => !!v || "Lütfen e-postanızı yazınız",
        (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    passwordRules() {
      return [(v: any) => !!v || "Lütfen parolanızı yazınız"];
    },
    returnUrl(): RawLocation {
      let returnVal: RawLocation = { name: "home" };

      if (this.$route.query.returnUrl) {
        returnVal = this.$route.query.returnUrl.toString();
      } else {
        const user = this.$store.getters["auth/user"];
        if (user.roles.indexOf("admin") >= 0) {
          returnVal = { name: "admin-home" };
        } else if (user.roles.indexOf("supervisor") >= 0) {
          returnVal = { name: "supervisor-home" };
        } else {
          returnVal = { name: "my-courses" };
        }
      }

      return returnVal;
    }
  },
  methods: {
    validate() {
      const form = this.$refs.form as HTMLFormElement;
      return form.validate();
    },
    reset() {
      const form = this.$refs.form as HTMLFormElement;
      form.reset();
    },
    resetValidation() {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    },
    async signin() {
      if (this.validate()) {
        let isContinue = true;

        try {
          // Kullanıcı adı ve parolası ile giriş yap
          const uCred = await firebase
            .auth()
            .signInWithEmailAndPassword(this.form.email, this.form.password);

          // Eğer kullanıcı yoksa işlemi sonlandır
          if (!uCred.user) return;

          // Kullanıcı bilgilerini al
          const user = {
            id: uCred.user.uid,
            displayName: uCred.user.displayName,
            email: uCred.user.email,
            roles: [] as string[] // Roller kurumsal kullanıcı kaydından alınacak
          };

          // Aktif bir supervisor davetiye var mı kontrol et.
          // Davetiye varsa yeni bir supervisor kullanıcı kaydı oluştur.
          // *****************************************
          const svInvitation = this.$store.getters["auth/supervisorInvitation"];

          if (svInvitation) {
            // Davetiyeyi onayla
            await firebase
              .firestore()
              .doc("businessSupervisorInvitations/" + svInvitation.id)
              .update({
                uid: user.id,
                isAccepted: true,
                dateOpened: firebase.firestore.FieldValue.serverTimestamp()
              });

            // Davetiyeyi sil
            this.$store.commit("auth/setSupervisorInvitation", null);

            // Kullanıcıyı içeri al
            this.$store.dispatch("auth/signIn", user);

            this.error = false;
            this.alert = true;
            this.message = "Bilgileriniz doğrulandı. Lütfen bekleyin...";

            // returnUrl yönlendir
            setTimeout(() => {
              this.$router.push(this.returnUrl);
            }, 1000);

            isContinue = false;
          }

          // ********************
          // Aktif bir davetiye var mı kontrol et.
          // Davetiye varsa yeni bir kurumsal kullanıcı kaydı oluştur
          if (isContinue) {
            const invitation = this.$store.getters["auth/invitation"];

            if (invitation) {
              // Davetiyeyi onayla
              await firebase
                .firestore()
                .doc("businessUserInvitations/" + invitation.id)
                .update({
                  uid: user.id,
                  isAccepted: true,
                  dateOpened: firebase.firestore.FieldValue.serverTimestamp()
                });

              // Davetiyeyi sil
              this.$store.commit("auth/setInvitation", null);

              // Kullanıcıyı içeri al
              this.$store.dispatch("auth/signIn", user);

              this.error = false;
              this.alert = true;
              this.message = "Bilgileriniz doğrulandı. Lütfen bekleyin...";

              // returnUrl yönlendir
              setTimeout(() => {
                this.$router.push(this.returnUrl);
              }, 1000);

              isContinue = false;
            }
          }

          // ******************************
          // Bir davetiye yoksa kullanıcı ve supervisor hesaplarını kontrol et.
          // ******************************
          if (isContinue) {
            // Check user is a supervisor. If not sign out.
            const qsSvInv = await firebase
              .firestore()
              .collection("businessSupervisorInvitations")
              .where("uid", "==", user.id)
              .get();

            if (!qsSvInv.empty) {
              // const svInv = snapshot.docs[0].data();
              user.roles.push("supervisor");

              // Kullanıcıyı içeri
              await this.$store.dispatch("auth/signIn", user);

              this.error = false;
              this.alert = true;
              this.message = "Bilgileriniz doğrulandı. Lütfen bekleyin...";

              // returnUrl yönlendir
              setTimeout(() => {
                this.$router.push(this.returnUrl);
              }, 1000);

              isContinue = false;
            }
          }

          if (isContinue) {
            // Check user is a business user. If not sign out.
            const qsUserInv = await firebase
              .firestore()
              .collection("businessUserInvitations")
              .where("uid", "==", user.id)
              .get();

            if (!qsUserInv.empty) {
              const userInv = qsUserInv.docs[0].data();
              user.roles.push(userInv.roleId);

              // Kullanıcıyı içeri
              await this.$store.dispatch("auth/signIn", user);

              this.error = false;
              this.alert = true;
              this.message = "Bilgileriniz doğrulandı. Lütfen bekleyin...";

              // returnUrl yönlendir
              setTimeout(() => {
                this.$router.push(this.returnUrl);
              }, 1000);

              isContinue = false;
            }
          }

          // *********************
          // Eğer supervisor ve kullanıcı bilgileri doğrulanamasıysa uturumu kapat
          // *********************
          if (isContinue) {
            this.$store.dispatch("auth/signOut");

            this.error = true;
            this.alert = true;
            this.message =
              "Kullanıcı bilgileriniz doğrulanamadı. Bilgilerinizi kontrol edip tekrar deneyebilirsiniz.";
          }
        } catch (err) {
          const e = err as firebase.firestore.FirestoreError;

          this.error = true;
          this.alert = true;
          this.message = this.getErrorMessage(e.code);
        }
      }
    },
    getErrorMessage(code: string) {
      let msg = "";
      switch (code) {
        default:
          msg = "Kullanıcı adı / parola doğrulanamadı.";
      }
      return msg;
    }
  },
  mounted() {
    this.$emit("setTitle", "Giriş");
  }
});
